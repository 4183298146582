import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import authApi from '@/api/common/auth';
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
import { USER_LOG_STATUS_CODE } from "@/constant/config";
import { debounce } from 'vue-debounce';
const queryString = require('query-string');

export default {

    extends: baseComponent,

    data() {
        return {

        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
        }),
    },

    created() {

    },

    mounted() {
    },

    methods: {
        // Action 
        openPopup(command) {
            EventBus.$emit("openPopup", command);
        },
    }
}